import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Mitra extends BaseModel {
    fields =  {
      'title': {
        type: 'text',
        label: 'Judul',
        rules: 'required'
      },
      'sort': {
        type: 'number',
        label: 'Sort',
        rules: 'required'
      },
      'description': {
        type: 'text',
        label: 'Deskripsi',
        rules: 'required'
      },
      'image': {
        type: 'attachment',
        label: 'Gambar',
        rules: 'required'
      },
      'url':{
        type: 'url',
        label:'Url Paket',
        rules: ''
      }
    }
    endpoint = process.env.VUE_APP_API_URL + 'banners'
}